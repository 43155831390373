import { KENALL } from '@ken-all/kenall';
import { triggerFieldValidation } from 'app_patagonia_base/cartridge/client/default/js/util/helpers';

if (window.patagonia?.clientDataModel?.kenall) {
    const kenallData = window.patagonia.clientDataModel.kenall;
    const config = { timeout: kenallData?.timeout ? kenallData?.timeout : 5000 };
    const checkout = !!document.getElementById('checkout-main');
    const kenallModalPresent = !!document.getElementById('kenallModal');
    const kenallInlinePresent = !!document.querySelector('.js-kenall-inline-container');
    let kenallModal = false;

    /**
     * @function
     * @description Sets custom select to prefecture
     * @param {string} prefecture - prefecture
     * @param {Object} form - javascript element of parent form
     */
    const setPrefecture = (prefecture, form) => {
        const select = form.querySelector('select[name$=_state]');
        select.value = prefecture.toLowerCase();
        select.dispatchEvent(new Event('change'));
    };

    /**
     * @function
     * Enables or disables state and city inputs for the current checkout stage
     * @param {Boolean} enable - true if should enable, false if should disable
     */
    const enableStateCityEditing = (enable) => {
        let state;
        let city;

        if (checkout) {
            const stage = document
                .getElementById('checkout-main')
                .getAttribute('data-checkout-stage');
            const form = document.querySelector(
                stage === 'shipping' ? '.shipping-address-form' : '.billing-payment-address-form'
            );
            state = form.querySelector('select[name$="_state"]').closest('label');
            city = form.querySelector('input[name$="_city"]').closest('label');
        } else {
            state = document.querySelector('select[name$="_state"]').closest('label');
            city = document.querySelector('input[name$="_city"]').closest('label');
        }

        if (enable) {
            state.classList.remove('is-disabled');
            city.classList.remove('is-disabled');
        } else {
            state.classList.add('is-disabled');
            city.classList.add('is-disabled');
        }
    };

    /**
     * @function
     * Remove Inline Select
     * @param {Object} form - javascript element of parent form
     * @param {Boolean} inline - whether this is an inline form element or in a modal
     */
    const removeInlineSelect = (form) => {
        // Remove select
        const inlineContainer = form.querySelector('.js-kenall-inline-container');
        if (inlineContainer) {
            inlineContainer.innerHTML = '';
        }

        // Remove postal error
        const errorContainer = form.querySelector('.js-invalid-feedback-postal');
        if (errorContainer) {
            errorContainer.innerText = '';
            errorContainer.style.display = 'none';
        }
    };

    /**
     * @function
     * Handles update button click or select change, setting the selected prefecture and city
     * @param {Boolean} inline - whether this is an inline form element or in a modal
     */
    const handleUpdate = (inline) => {
        const locations = document.getElementById('kenall-location-select');
        let form;

        // Validate a prefecture/city is selected
        const valid = locations && locations.selectedIndex > 0;

        // If nothing selected, show error
        if (!valid) {
            const error = document.getElementById('kenall-update-error');
            error.innerText = kenallData?.errorText ? kenallData?.errorText : '';
            error.style.display = 'block';
            return;
        }

        // Get selected prefecture/city
        const selectedOption = locations.options[locations.selectedIndex];
        const city = selectedOption.getAttribute('data-city');
        const prefecture = selectedOption.getAttribute('data-prefecture');
        const addressOne = selectedOption.getAttribute('data-addressone');

        // Get current form
        if (checkout) {
            const stage = document
                .getElementById('checkout-main')
                .getAttribute('data-checkout-stage');
            form = document.querySelector(
                stage === 'shipping' ? '.shipping-address-form' : '.billing-payment-address-form'
            );
        } else {
            form = document.querySelector('input[name$="postal"]').closest('form');
        }

        // Set prefecture/city/address1
        setPrefecture(prefecture, form);
        form.querySelector('input[name$="city"]').value = city; // eslint-disable-line no-param-reassign

        triggerFieldValidation(form.querySelector('select[name$=state]'));
        triggerFieldValidation(form.querySelector('input[name$="city"]'));

        if (addressOne) {
            form.querySelector('input[name$="address1"]').value = addressOne; // eslint-disable-line no-param-reassign
            triggerFieldValidation(form.querySelector('input[name$="address1"]'));
        }

        if (inline) {
            removeInlineSelect(form);

            // Disable and show city and prefecture fields
            enableStateCityEditing(false);
        } else {
            // Hide modal
            kenallModal.hide();
        }

        // Disable prefecture/city editing and display them
        enableStateCityEditing(false);

        if (form && (form.classList ? !form.classList.contains('suppress') : true)) {
            const pristine = new Pristine(form);
            pristine.validate();
        }
    };

    /**
     * @function
     * Calls handleUpdate when the update button is clicked in a modal
     */
    const handleModalUpdate = () => {
        handleUpdate(false);
    };

    /**
     * @function
     * Formats summary, prefecture, city, and addressOne strings from location response into object
     * @param {Object} location - Ken All location response
     * @returns {Object} - an object with summary, prefecture, city, and addressOne keys
     */
    const getLocationStrings = (location) => {
        const summaryAttributes = ['prefecture', 'city', 'town', 'koaza', 'building', 'floor'];
        const cityAttributes = ['city', 'town'];
        const addressOneAttributes = ['koaza', 'building', 'floor'];

        const locationSummaryArray = [];
        const cityAttributeArray = [];
        const addressOneAttributeArray = [];

        summaryAttributes.forEach((attribute) => {
            if (location[attribute]) {
                locationSummaryArray.push(location[attribute]);
            }
        });

        cityAttributes.forEach((attribute) => {
            if (location[attribute]) {
                cityAttributeArray.push(location[attribute]);
            }
        });

        addressOneAttributes.forEach((attribute) => {
            if (location[attribute]) {
                addressOneAttributeArray.push(location[attribute]);
            }
        });

        return {
            prefecture: location.prefecture,
            summary: locationSummaryArray.join(''),
            city: cityAttributeArray.join(''),
            addressOne: addressOneAttributeArray.join(''),
        };
    };

    /**
     * @function
     * Populates the city and prefecture options from the Ken All response into a location select
     * @param {Array} data - filtered Ken All response array
     * @param {Object} container - javascript element to insert options into
     * @param {Boolean} inline - whether this is an inline form element or in a modal
     * @param {String} container - additional class to apply to custom-input element
     */
    const generateLocationSelect = (locations, container, inline, inputClass) => {
        container.innerHTML = ''; // eslint-disable-line no-param-reassign

        // Below builds HTML like this:
        // <div class="custom-input required kenall-inline-input">
        //     <label for="kenall-location-select">
        //         <select class="form-control" id="kenall-location-select" aria-describedby="kenall-location-select">
        //             <option>Please Select...</option>
        //             <option id="cityprefecture" value="City, Prefecture" data-city="City" data-prefecture="prefecture">City, Prefecture</option>
        //         </select>
        //         <div id="kenall-update-error" class="invalid-feedback"></div>
        //     </label>
        // </div>

        const selectWrapper = document.createElement('div');
        selectWrapper.classList.add('custom-input', 'required', 'kenall-inline-input');

        if (inputClass) {
            selectWrapper.classList.add(inputClass);
        }

        const selectLabel = document.createElement('label');
        selectLabel.htmlFor = 'kenall-location-select';
        selectWrapper.appendChild(selectLabel);

        const select = document.createElement('select');
        select.classList.add('form-control');
        select.id = 'kenall-location-select';
        select.setAttribute('aria-describedby', 'kenall-location-select');
        selectLabel.appendChild(select);

        const emptyOption = document.createElement('option');
        emptyOption.innerText = kenallData?.selectOption ? kenallData?.selectOption : '';
        select.appendChild(emptyOption);

        locations.forEach((location) => {
            const locationStrings = getLocationStrings(location);

            const option = document.createElement('option');
            option.id = locationStrings.summary;
            option.setAttribute('value', locationStrings.summary);
            option.setAttribute('data-city', locationStrings.city);
            option.setAttribute('data-prefecture', locationStrings.prefecture);
            option.setAttribute('data-addressone', locationStrings.addressOne);
            option.innerText = locationStrings.summary;
            select.appendChild(option);
        });
        if (inline) {
            const labelSpan = document.createElement('span');
            labelSpan.innerText = kenallData?.selectOption ? kenallData?.selectOption : '';
            selectLabel.appendChild(labelSpan);
        }

        const errorContainer = document.createElement('div');
        errorContainer.id = 'kenall-update-error';
        errorContainer.classList.add('invalid-feedback');
        selectLabel.appendChild(errorContainer);

        container.appendChild(selectWrapper);

        select.addEventListener('change', () => {
            errorContainer.style.display = 'none';

            if (inline) {
                handleUpdate(true);
            }
        });
    };

    /**
     * @function
     * Creates an inline location selector
     * @param {Array} data - filtered Ken All response array
     * @param {Object} form - javascript element of parent form
     */
    const showInlineLocationOptions = (locations, form) => {
        const container = form.querySelector('.js-kenall-inline-container');
        const errorContainer = form.querySelector('.js-invalid-feedback-postal');

        if (!container) {
            enableStateCityEditing(true);
            return;
        }

        // Show error message underneath postal code
        errorContainer.innerText = kenallData?.inlineErrorText ? kenallData?.inlineErrorText : '';
        errorContainer.style.display = 'block';

        generateLocationSelect(locations, container, true, 'col-12');
    };

    /**
     * @function
     * Handles the response from Ken All
     * @param {Object} response - api response
     * @param {Object} form - javascript element of parent form
     */
    const handleResponse = (response, form) => {
        const locations = response.data;
        const displayInline = !!form.closest('.modal');

        if (locations.length === 0 || !form) {
            enableStateCityEditing(true);

            // Remove existing select if inline
            if (displayInline) {
                removeInlineSelect(form);
            }

            return;
        }

        if (locations.length > 1) {
            if (displayInline) {
                // If address editing is in a modal, display inline
                showInlineLocationOptions(locations, form);
            } else {
                // Update and display modal with location options
                generateLocationSelect(
                    locations,
                    document.querySelector('.js-kenall-options'),
                    false,
                    false
                );
                if (kenallModal) {
                    kenallModal.show();
                } else {
                    kenallModal = new window.bootstrap.Modal(
                        document.getElementById('kenallModal')
                    );
                    kenallModal.show();
                }
            }
        } else {
            const locationStrings = getLocationStrings(locations[0]);

            // Set prefecture, city, and address1
            setPrefecture(locationStrings.prefecture, form);
            form.querySelector('input[name$="city"]').value = locationStrings.city; // eslint-disable-line no-param-reassign
            enableStateCityEditing(false);
            triggerFieldValidation(form.querySelector('select[name$=state]'));
            triggerFieldValidation(form.querySelector('input[name$="city"]'));

            if (locationStrings.addressOne) {
                form.querySelector('input[name$="address1"]').value = locationStrings.addressOne; // eslint-disable-line no-param-reassign
                triggerFieldValidation(form.querySelector('input[name$="address1"]'));
            }

            // Remove existing select if inline
            if (displayInline) {
                removeInlineSelect(form);
            }
        }

        if (form && (form.classList ? !form.classList.contains('suppress') : true)) {
            const pristine = new Pristine(form);
            pristine.validate();
        }
    };

    if (kenallModalPresent) {
        // Initialize modal update button listener
        document.querySelector('.js-kenall-update').addEventListener('click', handleModalUpdate);
    }

    if (kenallModalPresent || kenallInlinePresent) {
        // Initialize the jpPostalCodeLookup event listener
        document.addEventListener('jpPostalCodeLookup', (e) => {
            try {
                const { postalCode } = e.detail;
                const form =
                    'input' in e.detail && e.detail.input ? e.detail.input.closest('form') : null;

                if (!postalCode || !form || !kenallData?.apiKey) {
                    enableStateCityEditing(true);
                    return;
                }

                new KENALL(kenallData?.apiKey, config)
                    .getAddress(postalCode)
                    .then((response) => {
                        handleResponse(response, form);
                    })
                    .catch(() => {
                        enableStateCityEditing(true);
                    });
            } catch (error) {
                console.error(error);
                enableStateCityEditing(true);
            }
        });
    }
}
